import _ from 'lodash';

const messages = {
  'required' : '이 필드는 필수입니다.',
  'email' : '올바른 이메일 주소를 입력하세요.',
  'currency' : 'Invalid characters entered, numbers only.',
  'passwordCheck' : '비밀번호는 8자 이상, 숫자 및 문자 1개 이상을 포함해야 합니다.',
  'passwordConfirm' : '비밀번호가 일치하지 않습니다.',
  'number' : '숫자만 입력 하세요.',
  'korean' : '영어와 숫자로만 입력하세요.',
  'float' : '실수 또는 정수로 입력하세요.',
  'decimalPoint1' : '숫자 또는 소수점 한자리 까지만 입력 가능합니다.',
  'minLength' : 'Please enter at least {0} characters.',
  'greaterThan' : '{0} 보다 큰 값을 입력하세요.'
};

const ErrorMessageFeedback = ({error, placeholder}) => {
  if(_.isNil(error)) {
    return <></>;
  }

  let msg = messages[error.type];
  if(error.type === 'minLength' || error.type === 'greaterThan') {
    msg = msg.replace('{0}', placeholder[error.type]);
  }

  return (
    <>
      <div className="invalid-feedback">{ msg }</div>
    </>
  );
};

export default ErrorMessageFeedback;