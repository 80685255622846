import {useMemo} from "react";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {http, Validator, Alert, toast} from "../../../utils";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {useNavigate} from "react-router-dom";

function AcInitLevel() {
  const initValue = useMemo(() => {
    return {
      userId: '',
      studentId: '',
      vocabularyLevel: '',
      functionLevel: '',
      grammarLevel: '',
      pronunciationLevel:'',
      ludiLevel:''
    }
  }, []);

    const navigate = useNavigate();

    const {handleSubmit, reset, formState: {errors}, control} = useForm({
        mode: 'onChange',
        defaultValues : { ...initValue }
    });

    const insertAction = useMutation((params) => http.post('/api/v1/report/ac-initLevel/apply', params));

    const onValid = (e) => {
        e.preventDefault();

        handleSubmit(async (data) => {
            const confirmMsg = '정말 해당 레벨로 레벨을 조정 하시겠습니까?';

            Alert.confirm(confirmMsg, async () => {
                try {
                    await insertAction.mutateAsync(data);
                    toast.success('완료 되었습니다.');

                    reset();

                    navigate({
                        pathname: '/adms/report/ac-initLevel/view'
                    });
                } catch (error) {
                    if (error.response && error.response.status === 500) {
                        // 서버 응답 코드가 500인 경우
                        toast.error('입력한 값이 정확한지 확인해 주세요.');
                    } else {
                        // 다른 종류의 에러 처리
                        console.error('에러 발생:', error);
                    }
                }

            });
        })();
    }


  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Init Level'
        }} />

        <div className="page-title">
          Ac InitLevel
        </div>

        <div className="search-condition">
          <Form onSubmit={onValid} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group required" controlId="id_userId">
                  <Form.Label>User Id</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        korean: (value) => Validator.validateKorean(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="User Id를 정확하게 입력해주세요."
                        isInvalid={errors.userId}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.userId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group required" controlId="id_studentId">
                  <Form.Label>Student Id</Form.Label>
                  <Controller
                      name="studentId"
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                          korean: (value) => Validator.validateKorean(value)
                        }
                      }}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="text"
                              placeholder="Student Id를 정확하게 입력해주세요."
                              isInvalid={errors.studentId}
                          />
                      }
                  />
                  <ErrorMessageFeedback error={errors.studentId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group required" controlId="id_vocabularyLevel">
                  <Form.Label>Vocabulary Level</Form.Label>
                  <Controller
                      name="vocabularyLevel"
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                            float: (value) => Validator.validateFloatingPointNumber(value)
                        }
                      }}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="number"
                              placeholder="조정하고자 하는 Vocabulary Level을 정확하게 입력해주세요."
                              isInvalid={errors.vocabularyLevel}
                          />
                      }
                  />
                  <ErrorMessageFeedback error={errors.vocabularyLevel} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group required" controlId="id_functionLevel">
                  <Form.Label>Function Level</Form.Label>
                  <Controller
                      name="functionLevel"
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                            float: (value) => Validator.validateFloatingPointNumber(value)
                        }
                      }}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="number"
                              placeholder="조정하고자 하는 Function Level을 정확하게 입력해주세요."
                              isInvalid={errors.functionLevel}
                          />
                      }
                  />
                  <ErrorMessageFeedback error={errors.functionLevel} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group required" controlId="id_grammarLevel">
                  <Form.Label>Grammar Level</Form.Label>
                  <Controller
                      name="grammarLevel"
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                            float: (value) => Validator.validateFloatingPointNumber(value)
                        }
                      }}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="number"
                              placeholder="조정하고자 하는 Grammar Level을 정확하게 입력해주세요."
                              isInvalid={errors.grammarLevel}
                          />
                      }
                  />
                  <ErrorMessageFeedback error={errors.grammarLevel} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_pronunciationLevel">
                  <Form.Label>Pronunciation Level</Form.Label>
                  <Controller
                      name="pronunciationLevel"
                      control={control}
                      rules={{
                        required: false,
                        validate: {
                          float: (value) => Validator.validateFloatingPointNumber(value)
                        }
                      }}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="number"
                              placeholder="조정하고자 하는 Pronunciation Level을 정확하게 입력해주세요."
                              isInvalid={errors.pronunciationLevel}
                          />
                      }
                  />
                  <ErrorMessageFeedback error={errors.pronunciationLevel} />
                </Form.Group>
              </Col>
            </Row>
              <Row>
                  <Col xs="12" md="6">
                    <Form.Group className="form-group" controlId="id_ludiLevel">
                      <Form.Label>Ludi Level</Form.Label>
                      <Controller
                          name="ludiLevel"
                          control={control}
                          rules={{
                              required: false,
                              validate: {
                                  float: (value) => Validator.validateFloatingPointNumber(value)
                              }
                          }}
                          render={({ field }) =>
                              <Form.Control
                                  {...field}
                                  type="number"
                                  placeholder="조정하고자 하는 ludi Level을 정확하게 입력해주세요."
                                  isInvalid={errors.ludiLevel}
                              />
                          }
                      />
                      <ErrorMessageFeedback error={errors.ludiLevel} />
                    </Form.Group>
                  </Col>
              </Row>
            <Row>
              <Col xs="12" className="btn-wrapper justify-content-between">
                <div>
                  <button className="btn btn-primary" type="submit">
                    <span className="material-icons">check</span> Apply
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AcInitLevel;