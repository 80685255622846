import {useMemo, useEffect} from "react";
import {Col, Row, Button} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import { http, toast } from '../../../utils';
import { history } from "../../../router/History";
import {useQuery} from "react-query";
import { useLocation } from "react-router-dom";
import StudentMission from "./StudentMission";
import StudentRecommedation from './StudentRecommedation';
import InteractionList from "../../game/InteractionInfo/InteractionList";
import ObjectUnlock from "./ObjectUnlock";

function StudentItem({student, interactionList, reSearchFlag}) {
  const {
    level,
    lastPortalCycle,
    lastAccesses,
    npcInfoList = [],
    setting = {}
  } = student;

  return (
    <>
      <Row>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Student Name</span> <span>{student.name}</span>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Birthday</span> <span>{student.birthday}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Gender</span> <span>{student.gender}</span>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Delete Date</span> <span>{student.deleteDate}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Build Code</span> <span>{lastAccesses.buildCode}</span>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Last Login Date</span> <span>{lastAccesses.startDate}</span>
          </div>
        </Col>

      </Row>
      <Row>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">OS Name</span> <span>{lastAccesses.osName}</span>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Last Logout Date</span> <span>{lastAccesses.endDate}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <div className="detail-row">
            <span className="label">Device Name</span> <span>{lastAccesses.deviceName}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <div className="detail-row">
            <span className="label">Total Level</span> <span className="level">{(Math.floor(level.totalLevel * 10000)/10000).toFixed(3)}</span>
          </div>
        </Col>
        <Col xs="6">
          <div className="detail-row">
            <span className="label">Ludi Level</span> <span className="level">{(Math.floor(level.ludiLevel * 10000)/10000).toFixed(3)}</span>
          </div>
        </Col>
        <Col xs="6">
          <div className="detail-row">
            <span className="label">Portal Cycle</span> <span className="level">{student.portalData?.order}</span>
          </div>
        </Col>
        <Col xs="6">
          <div className="detail-row">
            <span className="label">한영 번역</span> <span className="level">{setting.korEngTranslation}</span>
          </div>
        </Col>
        <Col xs="6">
          <div className="detail-row">
            <span className="label">학습시간</span> <span className="level">{`${setting.gameMinute || ''} 분`}</span>
          </div>
        </Col>
        <Col xs="12">
          <table className="level-table">
            <thead>
            <tr>
              <th>어휘</th>
              <th>대화</th>
              <th>문법</th>
              <th>발음</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{(Math.floor(level.currVocabularyLevel * 1000)/1000).toFixed(3)}</td>
              <td>{(Math.floor(level.currFunctionLevel * 1000)/1000).toFixed(3)}</td>
              <td>{(Math.floor(level.currGrammarLevel * 1000)/1000).toFixed(3)}</td>
              <td>{(Math.floor(level.currPronunciationLevel * 1000)/1000).toFixed(3)}</td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="3">
          <div className="detail-row">
            <span className="label">Last Clear Portal Cycle</span> <span>{student.portalData?.order}</span>
          </div>
        </Col>
        <Col xs="12" md="9">
          <div className="detail-row">
            <span className="label">Ludipower</span>
            <span className="me-3">{lastPortalCycle.ludiPower}</span>
            <span>{lastPortalCycle.endDate}</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <div className="sub-title mb-3">Zone Info</div>
        <ObjectUnlock student={student}/>
      </Row>
      <Row className="mt-4">
        <div className="sub-title mb-3">NPC Info</div>
        <Col xs="12" className="zone-info">
          {
            npcInfoList.map((npc, idx) =>
              <div key={idx} className={`${npc.unlock ? 'active' : ''}`}>{`${npc.name}_${npc.npcId}`}</div>
            )
          }
        </Col>
      </Row>

      <Row className="mt-4">
        <StudentMission studentId={student.id} reSearchFlag={reSearchFlag} />
      </Row>

      <Row className="mt-4">
        <StudentRecommedation studentId={student.id} reSearchFlag={reSearchFlag} />
      </Row>

      <Row className="mt-4">
        <div className="search-result">
          <div className="sub-title mb-3">
            <div>Interaction Info</div>
          </div>

          <div className="table-wrapper">
            <InteractionList resultList={interactionList} />
          </div>
        </div>
      </Row>
    </>
  );
}

function StudentDetail() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getStuddentId = () => {
    return searchParams.get('studentId');
  }

  const initStudentValue = useMemo(() => {
    return {
      level: {},
      lastPortalCycle: {},
      lastAccesses: {}
    }
  }, []);

  const { data: result, refetch : refetchSearch } = useQuery(['searchStudent'],
    async () => {
      const result = await http.get(`/api/v1/user/student/${getStuddentId()}`);
      return result.data;
    },
    {
      enabled: false,
      initialData: { ...initStudentValue },
      onSuccess: (data) => {
        if(!data.id) {
          toast.warning('검색 결과가 존재 하지 않습니다.');
        }
      }
    });

  const { data: resultInteractionList, refetch : refetchInteraction } = useQuery(
    ['searchInteractionInfo'],
    async () => {
      return (await http.get(`/api/v1/user/student/${getStuddentId()}/interaction`)).data;
    },
    {
      enabled: false,
      initialData: []
    });

  useEffect(() => {
    async function fetchData() {
      await refetchSearch();
      await refetchInteraction();
    }

    fetchData();
  }, [refetchSearch, refetchInteraction]);

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Student Info'
        }} />

        <div className="page-title">
          Student Info
        </div>

        <div className="text-end mt-3">
          <Button variant="outline-secondary" onClick={() => history.back() }><span className="material-icons">keyboard_backspace</span><span>Back</span></Button>
        </div>

        <div className="detail mt-4 student-info">
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">User ID</span> <span>{result?.userId}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Student ID</span> <span>{result?.id}</span>
                <span className={`ms-2 ${result?.login ? 'text-success fw-bold' : ''}`}>{`( ${result?.login ? 'Login' : 'Log Off'} )`}</span>
              </div>
            </Col>
          </Row>

          <StudentItem student={result} interactionList={resultInteractionList} />

        </div>
      </div>
    </>
  );
}

export default StudentDetail;