import {Fragment, useCallback, useMemo, useRef, useState} from "react";
import readXlsxFile from "read-excel-file";
import {useMutation} from "react-query";
import {toast} from "react-toastify";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Row} from "react-bootstrap";
import {http, Alert} from '../../../utils';
import DefaultTable from "../../../components/material/DefaultTable";

function CreateLMSAccount() {
    const [resultList, setResultList] = useState([])
    const [filename, setFilename] = useState("")

    const fileInput = useRef(null);

    const handleExcelUpload = e => {
        fileInput.current.click();
    }

    const handleExcelUploadChange = async (e) => {
        e.preventDefault()

        const map = {
            // 대문자는 인식을 못 함....
            ClassCode: "classCode",
            LudiId: "ludiId",
            Name: "name",
            Password:"password"
        }
        readXlsxFile(e.target.files[0], {map}).then((result => {
            setFilename(e.target.files[0].name)
            setResultList(result.rows)
        }))
    }

    const defaultSorted = useMemo(() => ({ id: 'userId', desc: true }), []);

    const fnSort = useCallback(async (sortBy) => {
    },[])

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
            textAlign: 'center',
        },
        {
            Header: "ClassCode",
            accessor: "classCode",
            textAlign: 'center',
        },
        {
            Header: "LudiId",
            accessor: "ludiId",
            textAlign: 'center',
        },
        {
            Header: "Password",
            accessor: "password",
            textAlign: 'center',
        }
    ],[])

    const insertAction = useMutation((param) => http.post("/api/v1/lms", param));
    const handleUpdate = async () => {
        const confirmMsg = '계정을 생성하시겠습니까?'
        const completeMsg = '생성되었습니다.';

        Alert.confirm(confirmMsg, async () => {
            const response = await insertAction.mutateAsync(resultList);

            console.log("response..", response);
            if (response.data === 'success') {
                toast.success(completeMsg);
            } else {
                // 'success'가 아닌 경우의 처리
                toast.error(`${response.data}의 이유로 생성에 실패하였습니다.`);
            }
        })
    }
    return (
        <>
            <div className="contents-fluid">
                <HelmetWrapper meta={{
                    title : 'LMS Create Account'
                }} />
                <div className="page-title">
                    LMS Create Account
                </div>
                <div className="search-condition">
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-end">
                            <span>업로드 파일명: {filename}</span>
                            &nbsp;&nbsp;&nbsp;
                            <Fragment>
                                <button className="btn btn-success" onClick={handleExcelUpload}>
                                    <span className="material-icons">file_upload</span>Excel Upload
                                </button>
                                <input type="file"
                                       name="file"
                                       ref={fileInput}
                                       onChange={handleExcelUploadChange}
                                       style={{ display: "none" }} />
                            </Fragment>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-end">
                            <button className="btn btn-danger" onClick={handleUpdate}>
                                <span className="material-icons">done</span>Create Account
                            </button>
                        </Col>
                    </Row>
                </div>
                <div className="page-title">
                    업로드 내역({resultList.length ? resultList.length : 0}건)
                </div>
                <div className="search-result">
                    <DefaultTable columns={columns}
                                  data={resultList}
                                  defaultSorted={defaultSorted}
                                  fnSort={fnSort}/>
                </div>
            </div>
        </>
    )
}
export default CreateLMSAccount;